/**
 * This is simply an array of JSON objects that hold various bits of information
 * about each of the possible badges we'll run into. This was manually compiled,
 * and should definitely be made more automation-friendly for future quests.
 */
export const badgeDetails = [
  {
    "code": "SQ0501",
    "issuer": "GA4O6I5J2BPHHQT3RNCOFVXUSUVILNUAQU4UZAHDAMSYFENR3QII4QDP",
    "filename": "SQ0501-GA4O6I5J2BPHHQT3RNCOFVXUSUVILNUAQU4UZAHDAMSYFENR3QII4QDP.png",
    "description": "DESCRIPTION",
    "owned": false,
    "soroban": true,
  },
  {
    "code": "SQ0502",
    "issuer": "GCMOPDUBGJZ6IZSD4WRCGAC3VUFHQNRZEPHM2UB2V3QWVAJ7NDGHOOG7",
    "filename": "SQ0502-GCMOPDUBGJZ6IZSD4WRCGAC3VUFHQNRZEPHM2UB2V3QWVAJ7NDGHOOG7.png",
    "description": "DESCRIPTION",
    "owned": false,
    "soroban": true,
  },
  {
    "code": "SQ0503",
    "issuer": "GATRDOIZ24ZOQR2VILU4ZED3NTMZCWSW3KI47QXT7LFZIGTIXFBTYCAA",
    "filename": "SQ0503-GATRDOIZ24ZOQR2VILU4ZED3NTMZCWSW3KI47QXT7LFZIGTIXFBTYCAA.png",
    "description": "DESCRIPTION",
    "owned": false,
    "soroban": true,
  },
  {
    "code": "SQ0504",
    "issuer": "GCS4T4Z3E6WIRYGLW7BHKDZU2EAQBVB3PPE7UMG24OCXAFNLELIHKMJ3",
    "filename": "SQ0504-GCS4T4Z3E6WIRYGLW7BHKDZU2EAQBVB3PPE7UMG24OCXAFNLELIHKMJ3.png",
    "description": "DESCRIPTION",
    "owned": false,
    "soroban": true,
  },
  {
    "code": "SQ0505",
    "issuer": "GBTN5KHEQC52MQLUYJDURUN2TSAPYOTSJPYXNXYRJ2GWQIM3MBB6EF6W",
    "filename": "SQ0505-GBTN5KHEQC52MQLUYJDURUN2TSAPYOTSJPYXNXYRJ2GWQIM3MBB6EF6W.png",
    "description": "DESCRIPTION",
    "owned": false,
    "soroban": true,
  },
  {
    "code": "SQ0506",
    "issuer": "GAIUESMWDEDXVODVZXXDPFC3FEUKRBUIRYDFGIAMH6EUWRKFQNL5LJB7",
    "filename": "SQ0506-GAIUESMWDEDXVODVZXXDPFC3FEUKRBUIRYDFGIAMH6EUWRKFQNL5LJB7.png",
    "description": "DESCRIPTION",
    "owned": false,
    "soroban": true,
  },
  {
    "code": "SQL0001",
    "issuer": "GDQMNGUDOSMCCN6MD52DPXX4ACECXVODFK2NQQGFXYLGXJFZ2LEEIY35",
    "filename": "SQL0001-GDQMNGUDOSMCCN6MD52DPXX4ACECXVODFK2NQQGFXYLGXJFZ2LEEIY35.png",
    "description": "Establish a cryptocurrency wallet with Albedo",
    "title": "Pioneer Quest",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0101",
    "issuer": "GDCBRDWFCCS7MY7BJREOLLEXYTWUFDGIEZZOXPD7EALXHDFCQY3QL7AE",
    "filename": "SQL0101-GDCBRDWFCCS7MY7BJREOLLEXYTWUFDGIEZZOXPD7EALXHDFCQY3QL7AE.png",
    "description": "Create an account on the Stellar network",
    "title": "Create Account",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0102",
    "issuer": "GB76OH7Z2N4BWUZREZTJ36WUDMAFCDYISM26MCNTJWZQ63D3NROYKBOZ",
    "filename": "SQL0102-GB76OH7Z2N4BWUZREZTJ36WUDMAFCDYISM26MCNTJWZQ63D3NROYKBOZ.png",
    "description": "Send an amount of a specific asset to a destination account",
    "title": "Payment",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0103",
    "issuer": "GDOPCKCQEXJFVXLWHHGVIVXYT5FKKYWRPPLPKK32RPNCKXURKZUVPJTG",
    "filename": "SQL0103-GDOPCKCQEXJFVXLWHHGVIVXYT5FKKYWRPPLPKK32RPNCKXURKZUVPJTG.png",
    "description": "Create a trustline between two accounts for a designated asset",
    "title": "Change Trust",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0104",
    "issuer": "GCEUS7FJMZWV5MGYQRUF6SR3T3IQVPASWXMT6REV5CB76N4RGOWST4JU",
    "filename": "SQL0104-GCEUS7FJMZWV5MGYQRUF6SR3T3IQVPASWXMT6REV5CB76N4RGOWST4JU.png",
    "description": "Create an offer to buy or sell a specific asset for another",
    "title": "Manage Offers",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0105",
    "issuer": "GABPJ5EWLQBVTVMHJPMF7DZ5OOZXR3JRTA3EBZEMJII6C57UEQDP4HNO",
    "filename": "SQL0105-GABPJ5EWLQBVTVMHJPMF7DZ5OOZXR3JRTA3EBZEMJII6C57UEQDP4HNO.png",
    "description": "Send or receive an asset that is different from the received or sent asset",
    "title": "Path Payments",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0201",
    "issuer": "GBOKD6LRD3TRNHCEOBLD44MPE3KXUF3NBXNBH2IYZHIHGG7KXZ3VANS7",
    "filename": "SQL0201-GBOKD6LRD3TRNHCEOBLD44MPE3KXUF3NBXNBH2IYZHIHGG7KXZ3VANS7.png",
    "description": "Delete an account by transferring its XLM balance to another account",
    "title": "Account Merge",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0202",
    "issuer": "GBYKVKGE3Z3YZFR4X4OXV4R5U5VZ52BZKFVRO3QOD62CQDBHJ6EN4RBK",
    "filename": "SQL0202-GBYKVKGE3Z3YZFR4X4OXV4R5U5VZ52BZKFVRO3QOD62CQDBHJ6EN4RBK.png",
    "description": "Add a data entry to an account",
    "title": "Manage Data",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0203",
    "issuer": "GAEQEWJ4SFB5U2HLW6RJA3Z2VWYKOTFVGRROFH3MNIOGDWWH5LDS3UG6",
    "filename": "SQL0203-GAEQEWJ4SFB5U2HLW6RJA3Z2VWYKOTFVGRROFH3MNIOGDWWH5LDS3UG6.png",
    "description": "Set the home domain of an account",
    "title": "Set Options - Home Domain",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0204",
    "issuer": "GD42KD354VPRHU3ZD3T6UEVXCABJU242H7MUEWNW7CNQTPSLK3AOIOGH",
    "filename": "SQL0204-GD42KD354VPRHU3ZD3T6UEVXCABJU242H7MUEWNW7CNQTPSLK3AOIOGH.png",
    "description": "Set the master key’s weight, determine the operation threshold, and manage signers",
    "title": "Set Options - Weights, Thresholds, and Signers",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0205",
    "issuer": "GC64TX2VL5YVNQ7YSTXQAYEQDTU3KYNWQUVPCTL5Z7XULA74LJLC4H27",
    "filename": "SQL0205-GC64TX2VL5YVNQ7YSTXQAYEQDTU3KYNWQUVPCTL5Z7XULA74LJLC4H27.png",
    "description": "Control access to an asset by setting flags on its issuing account",
    "title": "Set Flags",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0301",
    "issuer": "GCPFKCAL2YBIHX66MBF465NAF5KL55A6EJI7C4ZHAVIRAUKTNM3BKSJT",
    "filename": "SQL0301-GCPFKCAL2YBIHX66MBF465NAF5KL55A6EJI7C4ZHAVIRAUKTNM3BKSJT.png",
    "description": "Increase the sequence number of an account to a new given sequence number",
    "title": "Bump Sequence",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0302",
    "issuer": "GBPFHCWQEKMD6TS6Z73N2FQHMIIHB3Q6223LOIN3RSHNKUFFFEEL2LQD",
    "filename": "SQL0302-GBPFHCWQEKMD6TS6Z73N2FQHMIIHB3Q6223LOIN3RSHNKUFFFEEL2LQD.png",
    "description": "Pay account subentry reserves with another account",
    "title": "Sponsorship",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0303",
    "issuer": "GCS53T3NE3TWJXZRW6KYNQV5HIQ45SOGVUEE5Q4UIX6Z4SUANTKBTDQ7",
    "filename": "SQL0303-GCS53T3NE3TWJXZRW6KYNQV5HIQ45SOGVUEE5Q4UIX6Z4SUANTKBTDQ7.png",
    "description": "Send an amount of an asset from a source account to be accepted by a receiving account",
    "title": "Claimable Balances",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0304",
    "issuer": "GAF4P42BZL2RX4P5ZZYW4XCW4EZGL5WD7EJTTOFZ5IH2P2HFMI6SPZ4Z",
    "filename": "SQL0304-GAF4P42BZL2RX4P5ZZYW4XCW4EZGL5WD7EJTTOFZ5IH2P2HFMI6SPZ4Z.png",
    "description": "Burn an amount of a specific asset from a receiving account",
    "title": "Clawbacks",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SQL0305",
    "issuer": "GBA35OV7NKUAV7TD4P7UIK2RNQGY2LKFOO4XTNKSZLJRNBL5JXL5BBXL",
    "filename": "SQL0305-GBA35OV7NKUAV7TD4P7UIK2RNQGY2LKFOO4XTNKSZLJRNBL5JXL5BBXL.png",
    "description": "Deposit or withdraw assets of a liquidity pool in exchange for pool shares",
    "title": "Liquidity Pools",
    "owned": false,
    "learn": true,
  },
  {
    "code": "SSQL01",
    "issuer": "GAOILZ7SVHGY7ZXBGRD2JPUFUR4BR2ZQCS4M2J4NKR2FSFSHFZPU44GY",
    "filename": "SSQL01-GAOILZ7SVHGY7ZXBGRD2JPUFUR4BR2ZQCS4M2J4NKR2FSFSHFZPU44GY.png",
    "description": "Pay the transaction fee for another account",
    "title": "Fee-Bump Transactions",
    "owned": false,
    "side": true,
  },
  {
    "code": "SSQL02",
    "issuer": "GANLKSR75HSQOTZRMXYKV7O453XJKJ2ZZXCRUV3CYO5OIEOPQBU5HNPN",
    "filename": "SSQL02-GANLKSR75HSQOTZRMXYKV7O453XJKJ2ZZXCRUV3CYO5OIEOPQBU5HNPN.png",
    "description": "Publish an NFT on the Stellar blockchain",
    "title": "Mint an NFT on Stellar",
    "owned": false,
    "side": true,
  },
  {
    "code": "SQ0101",
    "issuer": "GBSTM6QKNYQND77XZR3CJN6Y5JALYGNLE5AL5CUB4OYPY2X6C62VPXOI",
    "filename": "SQ0101-GBSTM6QKNYQND77XZR3CJN6Y5JALYGNLE5AL5CUB4OYPY2X6C62VPXOI.png",
    "description": "Create and fund your account",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0102",
    "issuer": "GBSUMPWCVF2YPVL6YRCI6EUWOC7MKGATUH2ZMRKXBWHEDW7A2IWBOQ7N",
    "filename": "SQ0102-GBSUMPWCVF2YPVL6YRCI6EUWOC7MKGATUH2ZMRKXBWHEDW7A2IWBOQ7N.png",
    "description": "Add a memo and send a payment",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0103",
    "issuer": "GBIYXZVXEMPFBKIYLQF7A2TL54YALCIQKZKEIPKL6HJ3OYVF6HAAON7T",
    "filename": "SQ0103-GBIYXZVXEMPFBKIYLQF7A2TL54YALCIQKZKEIPKL6HJ3OYVF6HAAON7T.png",
    "description": "Add data to the blockchain",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0104",
    "issuer": "GCATB7XLELFXGGMSO5W6ZWUMA27SQAV7KGR6QJCJ57E6WJPQIR2IJYNR",
    "filename": "SQ0104-GCATB7XLELFXGGMSO5W6ZWUMA27SQAV7KGR6QJCJ57E6WJPQIR2IJYNR.png",
    "description": "Enable multisig transactions",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0105",
    "issuer": "GA7WXO3ZUESRMKAY47CYABQZ4VX3DZE27M2JDDB5OUPNE3IU45WWLP3Z",
    "filename": "SQ0105-GA7WXO3ZUESRMKAY47CYABQZ4VX3DZE27M2JDDB5OUPNE3IU45WWLP3Z.png",
    "description": "Create, issue, and acquire a custom asset",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0106",
    "issuer": "GA7AUESLEP4NOFZDROK6WO5Y7A3QLLWFOYVWN7NA2JAAXXWHH7I4AQ74",
    "filename": "SQ0106-GA7AUESLEP4NOFZDROK6WO5Y7A3QLLWFOYVWN7NA2JAAXXWHH7I4AQ74.png",
    "description": "Make an offer to sell that asset",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0107",
    "issuer": "GB5I564D7L2HZESHQ4QDWSXYZWAL3CH5Y625NN7HGYM77K6ABSBK6HQR",
    "filename": "SQ0107-GB5I564D7L2HZESHQ4QDWSXYZWAL3CH5Y625NN7HGYM77K6ABSBK6HQR.png",
    "description": "Pay the transaction fee from another account",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0108",
    "issuer": "GCGBARSEIHRZAL5JOMEPJRLXAVH5YBXIK2F6FA7J6PPREQSQERD5WRGT",
    "filename": "SQ0108-GCGBARSEIHRZAL5JOMEPJRLXAVH5YBXIK2F6FA7J6PPREQSQERD5WRGT.png",
    "description": "Acquire SRT using a path payment",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0101",
    "issuer": "GAOVDKTAKB4VEDN4CUY6F3WP4Q2O3ME46XRVHO67M3645JMF5A4PH276",
    "filename": "SQ0101-GAOVDKTAKB4VEDN4CUY6F3WP4Q2O3ME46XRVHO67M3645JMF5A4PH276.png",
    "description": "Create and fund your account",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0102",
    "issuer": "GCD5HRTHNH7OHURX6TVAHCN6UX42KPDM7E3QBBE5VJ3QGDRR34RTLZF6",
    "filename": "SQ0102-GCD5HRTHNH7OHURX6TVAHCN6UX42KPDM7E3QBBE5VJ3QGDRR34RTLZF6.png",
    "description": "Add a memo and send a payment",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0103",
    "issuer": "GCZ4O63TKVL6NIXSPU2BB63YQDADFC6LHAMO5W5ZDAIKAC7U5KJ4P7I4",
    "filename": "SQ0103-GCZ4O63TKVL6NIXSPU2BB63YQDADFC6LHAMO5W5ZDAIKAC7U5KJ4P7I4.png",
    "description": "Add data to the blockchain",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0104",
    "issuer": "GDGHLVKR3T5BTP5OT535DIZV5BSTI7A3DW4X6AIKFUNSW4PROCKTGU22",
    "filename": "SQ0104-GDGHLVKR3T5BTP5OT535DIZV5BSTI7A3DW4X6AIKFUNSW4PROCKTGU22.png",
    "description": "Enable multisig transactions",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0105",
    "issuer": "GDGEEGGWWKYJUFOIBNNCQFMJO6F5X5OELQQZW35X4CL2WLKLZKV2DDDI",
    "filename": "SQ0105-GDGEEGGWWKYJUFOIBNNCQFMJO6F5X5OELQQZW35X4CL2WLKLZKV2DDDI.png",
    "description": "Create, issue, and acquire a custom asset",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0106",
    "issuer": "GBW6YJIWHX5QFIKZZFMVNUQVIQAGPN3T5QYZTMBIATF5AXOAE4NRU4A6",
    "filename": "SQ0106-GBW6YJIWHX5QFIKZZFMVNUQVIQAGPN3T5QYZTMBIATF5AXOAE4NRU4A6.png",
    "description": "Make an offer to sell that asset",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0107",
    "issuer": "GBHFK7SK3MJH5RLDPGTJD7D3J3NZLGSBFRKWQRWB553JG7656PT3UFAQ",
    "filename": "SQ0107-GBHFK7SK3MJH5RLDPGTJD7D3J3NZLGSBFRKWQRWB553JG7656PT3UFAQ.png",
    "description": "Pay the transaction fee from another account",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0108",
    "issuer": "GANVXPLCAVE5QPNXMK5WMTLYB7KUKHW4LG5BAQGI5EPB53RJ4EEG23HT",
    "filename": "SQ0108-GANVXPLCAVE5QPNXMK5WMTLYB7KUKHW4LG5BAQGI5EPB53RJ4EEG23HT.png",
    "description": "Acquire SRT using a path payment",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0201",
    "issuer": "GBVVYEFWUIG7RXX6RBYJOFMGKEEEEN3TVEZYB5J6HNIQ5UON5O4NH23F",
    "filename": "SQ0201-GBVVYEFWUIG7RXX6RBYJOFMGKEEEEN3TVEZYB5J6HNIQ5UON5O4NH23F.png",
    "description": "Create and fund your account with a hashed memo",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0202",
    "issuer": "GCZODXV5HXRHHOZHWE57LMWIELKAXPKC64SOEBGTK7BV4GMRMOKYDIQQ",
    "filename": "SQ0202-GCZODXV5HXRHHOZHWE57LMWIELKAXPKC64SOEBGTK7BV4GMRMOKYDIQQ.png",
    "description": "Create, issue, and acquire a custom asset",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0203",
    "issuer": "GAAZYTMBB7POYKPQLBTCQL6HESM5XZR6OMBFHUCBXQ6UHKP6JMP3JDBD",
    "filename": "SQ0203-GAAZYTMBB7POYKPQLBTCQL6HESM5XZR6OMBFHUCBXQ6UHKP6JMP3JDBD.png",
    "description": "Create a fee bump transaction",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0204",
    "issuer": "GDHXJHJBX6WOVO7HFKXR3HQVQBK2JNVGJL44FSE2WBN2WKY76JHPSCOL",
    "filename": "SQ0204-GDHXJHJBX6WOVO7HFKXR3HQVQBK2JNVGJL44FSE2WBN2WKY76JHPSCOL.png",
    "description": "Create a claimable balance",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0205",
    "issuer": "GCBCEJJX6ZD4FVASYN6KFOZXN6ANGR4UOGTL7M6MX6GHLMS7IIMPOOTZ",
    "filename": "SQ0205-GCBCEJJX6ZD4FVASYN6KFOZXN6ANGR4UOGTL7M6MX6GHLMS7IIMPOOTZ.png",
    "description": "Claim that claimable balance",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0206",
    "issuer": "GBF3TKZ4FKU7VID5SUGWNGH54TNL7QEUZIIGJ3MP7HUDWALDL3UXVSNN",
    "filename": "SQ0206-GBF3TKZ4FKU7VID5SUGWNGH54TNL7QEUZIIGJ3MP7HUDWALDL3UXVSNN.png",
    "description": "Sponsor future reserves for another account",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0207",
    "issuer": "GBDW6K2TIS2OFKPW27HHEL5LCGQJRCG5YBMDR4R6EDPX2ZJQVGJ4ZQOO",
    "filename": "SQ0207-GBDW6K2TIS2OFKPW27HHEL5LCGQJRCG5YBMDR4R6EDPX2ZJQVGJ4ZQOO.png",
    "description": "Revoke the account sponsorship",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0208",
    "issuer": "GDME25VQY3L4GRLS22JY6RNTI6RNYWJ7T7KQEIPZHUO672NDPXS7EQUB",
    "filename": "SQ0208-GDME25VQY3L4GRLS22JY6RNTI6RNYWJ7T7KQEIPZHUO672NDPXS7EQUB.png",
    "description": "Set a home domain for your account",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0201",
    "issuer": "GB6MASTVPZINM4AS4QXI63JKGIV3PCWKUWWHD2UNBGLEK6Y7F2IDHKVO",
    "filename": "SQ0201-GB6MASTVPZINM4AS4QXI63JKGIV3PCWKUWWHD2UNBGLEK6Y7F2IDHKVO.png",
    "description": "Create and fund your account with a hashed memo",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0202",
    "issuer": "GCJ35JDQBYIPWQZMEF5AY6ONEF5VRC4ZO3BAZDPWBL7PZ2OBBVBWPZQ3",
    "filename": "SQ0202-GCJ35JDQBYIPWQZMEF5AY6ONEF5VRC4ZO3BAZDPWBL7PZ2OBBVBWPZQ3.png",
    "description": "Create, issue, and acquire a custom asset",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0203",
    "issuer": "GBB7ZIVXUIENUJTYCWCVXBSBORJBHNFS7GBM7IZL274P3E3OPA2PQL3R",
    "filename": "SQ0203-GBB7ZIVXUIENUJTYCWCVXBSBORJBHNFS7GBM7IZL274P3E3OPA2PQL3R.png",
    "description": "Create a fee bump transaction",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0204",
    "issuer": "GBWXWUM5LSAWFC7PHNZDYNMQDO5PXKA23UDBS6K5DPK6SWZUKS7Z36WA",
    "filename": "SQ0204-GBWXWUM5LSAWFC7PHNZDYNMQDO5PXKA23UDBS6K5DPK6SWZUKS7Z36WA.png",
    "description": "Create a claimable balance",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0205",
    "issuer": "GCW5SXKPRNKCNDITRKU3P6VLEUI356S2AKQ6YU6SKOFTJSX3GVCP4FUJ",
    "filename": "SQ0205-GCW5SXKPRNKCNDITRKU3P6VLEUI356S2AKQ6YU6SKOFTJSX3GVCP4FUJ.png",
    "description": "Claim that claimable balance",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0206",
    "issuer": "GCZX7ENHWSPG44IFJBWAEAXKEP3MYRRVVTUQ6VLTKMVRVKSG2EZCDD52",
    "filename": "SQ0206-GCZX7ENHWSPG44IFJBWAEAXKEP3MYRRVVTUQ6VLTKMVRVKSG2EZCDD52.png",
    "description": "Sponsor future reserves for another account",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0207",
    "issuer": "GAYOXIOS6V7L3P4KBPXJ2KBRAJA7PZHH6WMM4MTBNH7HAF5HZE4T6NC7",
    "filename": "SQ0207-GAYOXIOS6V7L3P4KBPXJ2KBRAJA7PZHH6WMM4MTBNH7HAF5HZE4T6NC7.png",
    "description": "Revoke the account sponsorship",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0208",
    "issuer": "GDL7QOHYQ6GWV4C3ZDUAM6IP5DL5W3DHF2PYFNSTAHJWANKVSSELI7PK",
    "filename": "SQ0208-GDL7QOHYQ6GWV4C3ZDUAM6IP5DL5W3DHF2PYFNSTAHJWANKVSSELI7PK.png",
    "description": "Set a home domain for your account",
    "monochrome": true,
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0301",
    "issuer": "GCO2YMX3FF4ITMJFSBANPESOIN676GENIWIKRSGK7JBYIZ3H7H4UW25M",
    "filename": "SQ0301-GCO2YMX3FF4ITMJFSBANPESOIN676GENIWIKRSGK7JBYIZ3H7H4UW25M.png",
    "description": "Make use of a sequence number bump operation in a transaction",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0302",
    "issuer": "GAKS33OJD3AZDCLSMYKWPU2JKY7BZSBGVNLS4EN5ZPFXQCKZMK63WGSL",
    "filename": "SQ0302-GAKS33OJD3AZDCLSMYKWPU2JKY7BZSBGVNLS4EN5ZPFXQCKZMK63WGSL.png",
    "description": "Submit a transaction containing 100 operations",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0303",
    "issuer": "GAZ3UXIBGWS2T72TLU7HH7NZQE2RORFO4KAFPTJ55PHBOFPXJHQHPTDS",
    "filename": "SQ0303-GAZ3UXIBGWS2T72TLU7HH7NZQE2RORFO4KAFPTJ55PHBOFPXJHQHPTDS.png",
    "description": "Submit a hash signed transaction",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0304",
    "issuer": "GBMLLI5EMUKUZO2APQYXZANTUET2D4QLG5N3ECEQZKQ62ET3MDZLKW5O",
    "filename": "SQ0304-GBMLLI5EMUKUZO2APQYXZANTUET2D4QLG5N3ECEQZKQ62ET3MDZLKW5O.png",
    "description": "Submit a pre-authorized transaction",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0305",
    "issuer": "GC6XLPVFEVZO5SJ4PADO4KQVSITOHADJLR7RZEC4A3SYLA3FORKXRRHI",
    "filename": "SQ0305-GC6XLPVFEVZO5SJ4PADO4KQVSITOHADJLR7RZEC4A3SYLA3FORKXRRHI.png",
    "description": "Successfully submit a clawback operation",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0306",
    "issuer": "GAQRBLAAKJNMXVNZKBFCJ6E2XVXTXBUOXIAOQQNJBCUXBCZKR3HOLT2S",
    "filename": "SQ0306-GAQRBLAAKJNMXVNZKBFCJ6E2XVXTXBUOXIAOQQNJBCUXBCZKR3HOLT2S.png",
    "description": "Mint a Stellar Quest style NFT",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0307",
    "issuer": "GAATGXGABT7HB7ALO3TAGVBVDE5B24LMSUQ3EKNCJHO5QBY4D5G5DZX5",
    "filename": "SQ0307-GAATGXGABT7HB7ALO3TAGVBVDE5B24LMSUQ3EKNCJHO5QBY4D5G5DZX5.png",
    "description": "Acquire and make use of a SEP-0010 JWT",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0308",
    "issuer": "GBZS5RP2YJDCF5RAJHXOHUXSBXD5KTDASIVSGETDYYI6OTPUS5ZFKIYA",
    "filename": "SQ0308-GBZS5RP2YJDCF5RAJHXOHUXSBXD5KTDASIVSGETDYYI6OTPUS5ZFKIYA.png",
    "description": "Use SEP-0006 to acquire some MULT from the testanchor",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0401",
    "issuer": "GAICLDUA5WSJ3KQPIHQU4KNSJ7FAKVO35RFFIGZXY3ZML3T3YHRPHT7R",
    "filename": "SQ0401-GAICLDUA5WSJ3KQPIHQU4KNSJ7FAKVO35RFFIGZXY3ZML3T3YHRPHT7R.png",
    "description": "Gain access to an account using a secret key and hash(x) signer",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0402",
    "issuer": "GDJCU42KMWM4UCM4UZ3PNL3SDEH7LC6TTQTMXGKXZHSO2DHWBBYIGIVF",
    "filename": "SQ0402-GDJCU42KMWM4UCM4UZ3PNL3SDEH7LC6TTQTMXGKXZHSO2DHWBBYIGIVF.png",
    "description": "Claim your prize XLM from a non-native asset claimable balance",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0403",
    "issuer": "GBZMBLMCJEDIIM5IMWMFNHK35YXNXLUF3HLL2IYMFP7WRGDU5Y6OZVQQ",
    "filename": "SQ0403-GBZMBLMCJEDIIM5IMWMFNHK35YXNXLUF3HLL2IYMFP7WRGDU5Y6OZVQQ.png",
    "description": "Change the master key weight of an account using a preauth tx",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0404",
    "issuer": "GB5TXD7KKU7R4Z3ZJQBWRAA3DN3CWP6RAXU75SCIGA2HYJLW5FJZ234N",
    "filename": "SQ0404-GB5TXD7KKU7R4Z3ZJQBWRAA3DN3CWP6RAXU75SCIGA2HYJLW5FJZ234N.png",
    "description": "Red Pill or Blue Pill? Only a fool wouldn't Y2hvb3NlIHdpc2VseQ==",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0405",
    "issuer": "GCFAFBICWS5U4YK6NHJKOAHWX3VGCHQWKS2XMTK6NQCX2YCLUFPODADN",
    "filename": "SQ0405-GCFAFBICWS5U4YK6NHJKOAHWX3VGCHQWKS2XMTK6NQCX2YCLUFPODADN.png",
    "description": "Find a hidden hash(x) signer in a failed transaction",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SQ0406",
    "issuer": "GBP7IEA2U4QQUTP67ML56YNUC4RY34DKYPFOAAAYYZSJJY3YA55NCUBS",
    "filename": "SQ0406-GBP7IEA2U4QQUTP67ML56YNUC4RY34DKYPFOAAAYYZSJJY3YA55NCUBS.png",
    "description": "Claim 93 claimable balances ASAP to get your prize Lumens",
    "owned": false,
    "legacy": true,
  },
  {
    "code": "SSQ01",
    "issuer": "GANVCN5HYQHUTZ2QCJ4AZJBH3BQOETFDW7QZNBDEMHCO4O7I5QOUMFF2",
    "filename": "SSQ01-GANVCN5HYQHUTZ2QCJ4AZJBH3BQOETFDW7QZNBDEMHCO4O7I5QOUMFF2.png",
    "description": "Meridian 2020 Stellar side quest badge",
    "owned": false,
    "side": true,
  },
  {
    "code": "SSQ02",
    "issuer": "GBJYFJCADTIK7RGOMWSVTHIZPG747USOL6UJFYAK6OD4ADOEEYC2U72U",
    "filename": "SSQ02-GBJYFJCADTIK7RGOMWSVTHIZPG747USOL6UJFYAK6OD4ADOEEYC2U72U.png",
    "description": "Verified Stellar community member badge",
    "owned": false,
    "side": true,
  },
  {
    "code": "SSQ03",
    "issuer": "GABFDXV6EAUVHPTE6RCZ7YNLUOJDUJBRFFC5TBO7I3KB55JZR6ISMT27",
    "filename": "SSQ03-GABFDXV6EAUVHPTE6RCZ7YNLUOJDUJBRFFC5TBO7I3KB55JZR6ISMT27.png",
    "description": "Pinnacle 2021 NFT workshop badge",
    "owned": false,
    "side": true,
  },
  {
    "code": "SSQ04",
    "issuer": "GDV2JRD25G5QJS7Z5CW3RIXY4JNA6Y6GEZ5LJ6CSICQTIJCIWAOTAHTT",
    "filename": "SSQ04-GDV2JRD25G5QJS7Z5CW3RIXY4JNA6Y6GEZ5LJ6CSICQTIJCIWAOTAHTT.png",
    "description": "Meridian 2021 docsprint badge",
    "owned": false,
    "side": true,
  }
  // TODO: Create some kind of builder function that can:
  // 1. download the TOML file from the quest.stellar.org website,
  // 2. Parse the data into an array like this one,
  // 3. Download all the image files, constructing them from the on-chain data
  // 4. Populate the `/assets/badges` directory with those images.
]
